import { container } from 'inversify-props';
import SessionService from '@/services/session.service';
import AttendantService from '@/services/attendant.service';
import HttpService from '@/services/http.service';
import SintegraService from '@/services/sintegra.service';
import ClientService from '@/services/crm/client.service';
import LayoutEmailService from '@/services/configurator/layouts/layout-email.service';
import VariableTextService from '@/services/configurator/variables/variable-text.service';
import VariableColumnService from '@/services/configurator/variables/variable-column.service';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import GCCollectionService from '@/services/gc/configuration/collection.service';
import GCClassificationService from '@/services/gc/configuration/classification.service';
import RouterService from '@/services/router.service';
import OrderService from '@/services/crm/order.service';
import ContactService from '@/services/crm/contact.service';
import FinancialInformationService from '@/services/crm/financial-information.service';
import ProcessService from '@/services/crm/process.service';
import ActivityService from '@/services/crm/activity.service';
import RepresentativeService from '@/services/crm/representative.service';
import UploadService from '@/services/crm/upload.service';
import VisitMadeService from '@/services/crm/visit-made.service';
import SaleService from '@/services/crm/sale.service';
import LocalizationService from '@/services/localization.service';
import ClientManagementService from '@/services/crm/client-management.service';
import AttachmentService from '@/services/crm/attachment.service';
import EmailService from '@/services/crm/email.service';
import AttendanceService from '@/services/crm/attendance.service';
import GCGeneralConfigService from '@/services/gc/configuration.service';
import ConversationService from '@/services/crm/conversation.service';
import ProspectService from '@/services/crm/prospect.service';
import LeadService from '@/services/crm/lead.service';
import SettingsService from '@/services/crm/settings.service';
import ServiceQueueService from '@/services/crm/service-queue.service';
import StatisticsService from '@/services/crm/statistics.service';
import B2OrderAuditService from './services/b2/order-audit.service';
import MetasUploadService from './services/cr/metas-upload.service';
import MetasService from './services/cr/metas.service';
import MetasPeriodosService from './services/cr/metas-periodos.service';
import ContactChannelService from './services/crm/contact-channel.service';
import EoxService from './services/external/eox.service';
import CallCenterUserService from './services/crm/call-center-user.service';
import CallCenterCallService from './services/crm/call-center-call.service';
import JustificationManagementService from '@/services/crm/justification-management.service';
import ChatMessageSearchService from '@/services/crm/message-search/chat-message-search.service';

export default function buildDependencyContainer(): void {
  container.addSingleton(SessionService, InjectionIdEnum.SessionService);
  container.addSingleton(AttendantService, InjectionIdEnum.AttendantService);
  container.addSingleton(AttendanceService, InjectionIdEnum.AttendanceService);
  container.addSingleton(HttpService, InjectionIdEnum.HttpService);
  container.addSingleton(RouterService, InjectionIdEnum.RouterService);
  container.addSingleton(LocalizationService, InjectionIdEnum.LocalizationService);
  container.addSingleton(SintegraService, InjectionIdEnum.SintegraService);

  container.addSingleton(LayoutEmailService, InjectionIdEnum.ConfiguratorLayoutEmailService);
  container.addSingleton(VariableTextService, InjectionIdEnum.ConfiguratorVariableTextService);
  container.addSingleton(VariableColumnService, InjectionIdEnum.ConfiguratorVariableColumnService);

  container.addSingleton(MetasUploadService, InjectionIdEnum.CrMetasUploadService);
  container.addSingleton(MetasService, InjectionIdEnum.CrMetasService);
  container.addSingleton(MetasPeriodosService, InjectionIdEnum.CrMetasPeriodosService);

  container.addSingleton(SettingsService, InjectionIdEnum.CrmSettingsService);
  container.addSingleton(ClientService, InjectionIdEnum.CrmClientService);
  container.addSingleton(OrderService, InjectionIdEnum.CrmOrderService);
  container.addSingleton(ContactService, InjectionIdEnum.CrmContactService);
  container.addSingleton(FinancialInformationService, InjectionIdEnum.CrmFinancialInformationService);
  container.addSingleton(ProcessService, InjectionIdEnum.CrmProcessService);
  container.addSingleton(ActivityService, InjectionIdEnum.CrmActivityService);
  container.addSingleton(RepresentativeService, InjectionIdEnum.CrmRepresentativeService);
  container.addSingleton(UploadService, InjectionIdEnum.CrmUploadService);
  container.addSingleton(VisitMadeService, InjectionIdEnum.CrmVisitMadeService);
  container.addSingleton(SaleService, InjectionIdEnum.CrmSaleService);
  container.addSingleton(ClientManagementService, InjectionIdEnum.CrmClientManagementService);
  container.addSingleton(AttachmentService, InjectionIdEnum.CrmAttachmentService);
  container.addSingleton(EmailService, InjectionIdEnum.CrmEmailService);
  container.addSingleton(AttendanceService, InjectionIdEnum.CrmAttendanceService);
  container.addSingleton(ConversationService, InjectionIdEnum.CrmConversationService);
  container.addSingleton(ProspectService, InjectionIdEnum.CrmProspectService);
  container.addSingleton(LeadService, InjectionIdEnum.CrmLeadService);
  container.addSingleton(ContactChannelService, InjectionIdEnum.CrmContactChannelService);
  container.addSingleton(ServiceQueueService, InjectionIdEnum.CrmServiceQueueService);
  container.addSingleton(StatisticsService, InjectionIdEnum.CrmStatisticsService);
  container.addSingleton(ChatMessageSearchService, InjectionIdEnum.CrmChatMessageSearchService);

  container.addSingleton(GCCollectionService, InjectionIdEnum.GCCollectionService);
  container.addSingleton(GCGeneralConfigService, InjectionIdEnum.GCGeneralConfigService);
  container.addSingleton(GCClassificationService, InjectionIdEnum.GCClassificationService);

  container.addSingleton(B2OrderAuditService, InjectionIdEnum.B2OrderAuditService);

  container.addSingleton(CallCenterUserService, InjectionIdEnum.CallCenterUserService);
  container.addSingleton(CallCenterCallService, InjectionIdEnum.CallCenterCallService);
  container.addSingleton(JustificationManagementService, InjectionIdEnum.JustificationManagementService);

  // External
  container.addSingleton(EoxService, InjectionIdEnum.EoxService);
}
