import { ConversationMessageStatusEnum } from '@/enums/crm/conversation-message-status.enum';

export default class ConversationMessageStatusModel {
  id!: number;

  code!: ConversationMessageStatusEnum;

  name!: string;

  description!: string;
}
