export default class ChatMessageSearchResultModel {
  messageId!: number;

  conversationId!: number;

  protocol!: string;

  messageDisplay!: string;

  messageDate!: string;
}
