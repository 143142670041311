import { injectable } from 'inversify-props';
import BaseService from '@/services/base-service';
import { RequestConfig } from '@/interfaces/request-config.interface';
import ChatMessageSearchResultModel from '@/models/crm/chat-message-search-result.model';

@injectable()
export default class ChatMessageSearchService extends BaseService {
  public async search(term: string,
    number: string | undefined,
    clientId: string | null): Promise<ChatMessageSearchResultModel[]> {
    const config: RequestConfig = {
      params: {
        term,
        number,
        clientId,
      },
    };

    return (await this.httpService.get(
      ChatMessageSearchResultModel,
      `${this.getApiPath()}/crm/messages/search`,
      config,
    )) as ChatMessageSearchResultModel[];
  }
}
