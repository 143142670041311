import { Type } from 'class-transformer';
import ConversationMessageModel from '@/models/crm/conversation-message.model';
import ConversationLogModel from '@/models/crm/conversation-log.model';

export default class ConversationPreviousProtocolMessageModel {
  @Type(() => ConversationMessageModel)
  messages!: ConversationMessageModel[];

  @Type(() => ConversationLogModel)
  logs!: ConversationLogModel[];
}
