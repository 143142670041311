import { injectable } from 'inversify-props';
import { classToPlain } from 'class-transformer';
import { cloneDeep } from 'lodash';
import dayjs from '@/plugins/dayjs';
import BaseService from '@/services/base-service';
import ProspectModel from '@/models/crm/prospect.model';
import { RequestConfig } from '@/interfaces/request-config.interface';
import ProspectCityClientsModel from '@/models/crm/prospect-city-clients.model';

@injectable()
export default class ProspectService extends BaseService {
  async create(model: ProspectModel, editing = false): Promise<number> {
    return (await this.httpService.post(
      Number,
      `${this.getApiPath()}/prospect?fromPanel=true&editing=${editing}`,
      classToPlain(cloneDeep(model)),
    )) as number;
  }

  async update(model: ProspectModel): Promise<ProspectModel> {
    return (await this.httpService.put(
      ProspectModel,
      `${this.getApiPath()}/prospect/${model.codProspect}`,
      classToPlain(cloneDeep(model)),
    )) as ProspectModel;
  }

  async quickSearch(keyword: string, ignoreConverted = false): Promise<ProspectModel[]> {
    return (await this.httpService.get(
      ProspectModel,
      `${this.getApiPath()}/prospect/quick-search?term=${
        encodeURIComponent(keyword).toString()}&ignoreConverted=${ignoreConverted}`,
    )) as ProspectModel[];
  }

  async getByCnpj(cnpj: string): Promise<ProspectModel> {
    const config: RequestConfig = {
      params: {
        cnpj,
      },
    };
    return (await this.httpService.get(ProspectModel, `${this.getApiPath()}/prospect`, config)) as ProspectModel;
  }

  async getContacts(id: number | undefined): Promise<Array<string>> {
    const config: RequestConfig = {
      params: {
        id,
      },
    };

    return (await this.httpService.get(String, `${this.getApiPath()}/prospect/contacts`, config)) as Array<string>;
  }

  async get(id: number): Promise<ProspectModel> {
    const config: RequestConfig = {
      params: {
        id,
      },
    };
    return (await this.httpService.get(ProspectModel, `${this.getApiPath()}/prospect`, config)) as ProspectModel;
  }

  async transformToClient(codProspect: string): Promise<string> {
    return (await this.httpService.post(
      String,
      `${this.getApiPath()}/prospect/transform-to-client/${codProspect}`,
    )) as string;
  }

  async getAddressByCep(prospect: ProspectModel): Promise<ProspectModel> {
    const prospectAux = classToPlain(cloneDeep(prospect));
    return (await this.httpService.post(
      ProspectModel,
      `${this.getApiPath()}/prospect/find-address`,
      prospectAux,
    )) as ProspectModel;
  }

  delete(id: string): Promise<void> {
    return this.httpService.delete(`${this.getApiPath()}/prospect/${id}`);
  }

  async getProspectCityClientsInfo(
    numero: string,
    endereco: string,
    municipio: string,
    uf: string,
  ): Promise<ProspectCityClientsModel[]> {
    const config: RequestConfig = {
      params: {
        municipio,
        numero,
        endereco,
        uf,
      },
    };
    return (await this.httpService.get(
      ProspectCityClientsModel,
      `${this.getApiPath()}/prospect/clients-city-info`,
      config,
    )) as ProspectCityClientsModel[];
  }

  static generateProspectCityClientsInfoExportFilename(date: Date, codIbge: string): string {
    return `Clientes_Cidade_${codIbge}_${dayjs(date).format('YYYY-MM-DD_HH-mm-ss')}.xlsx`;
  }
}
